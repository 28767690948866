import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './app';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
	// <StrictMode>
		<App />
	// </StrictMode>
);
